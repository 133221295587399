import { Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from './auth.service';
import { AuthState } from '../store/auth.state';
import { Navigate } from '@ngxs/router-plugin';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private store: Store) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const auth: any = this.store.selectSnapshot(AuthState.auth);

    console.log('*** AUTH GUARD ', auth);

    if (!auth.token || !auth.roles.find(i => i.name === 'ADM')) {
      this.router.navigate(['auth/login'])
    }
    return true;

    /* const currentUser = this.authService.currentUserValue;
    if (currentUser) {
      return true;
    }

    this.authService.logout();
    return false; */
  }
}
