import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

import Amplify, { Auth } from "aws-amplify";

//************ PROD **************/

Amplify.configure({
  Auth: {
    identityPoolId:'us-east-2:a58c39be-9317-4aa3-9f88-04317a50745d',
    region: 'us-east-2',
    userPoolId: 'us-east-2_HfqyJCSpM',
    userPoolWebClientId: '2l68pvbeekim5vsjhkuaqred9s'
  },
  Storage: {
    AWSS3: {
        bucket: 'atria-images',
        region: 'us-east-2'
    }
  }
});

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
