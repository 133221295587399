export class Search {
    static readonly type = '[Search] Search';
    constructor(public term: string) {}
  }
  
  export class ClearSearch {
    static readonly type = '[Search] ClearSearch';
  }

  export interface SearchStateModel {
    term: string;
  }