import { environment } from "src/environments/environment";

export const SELECT_STATUS = [
  { value: 'published', label: "PUBLICADO" },
  { value: 'pending', label: "PENDENTE" }
]

export enum TOKEN_TYPE {
  TOKEN = 'TOKEN',
  DEVICE = 'DEVICE',
  TWO_FACTOR = 'TWO_FACTOR',
  CREATE_FACTOR = 'CREATE_FACTOR'
}

export const BIKE_TYPES = [
  { value: 'mountainbike', label: "Mountain Bike", enable: false},
  { value: 'speed', label: "Speed", enable: false},
  { value: 'bikeurbain', label: "Bike Urbana", enable: false },
  { value: 'bmx', label: "Bmx", enable: false }
]

export class STATUS
{
    static PUBLICADO = "published";
    static PENDENTE = "pending";
}

export class Constants {
  private static V1 = '/v1/';
  private static idioma = '/pt';
  public static IMAGE_PATH = 'https://atria-images.s3.amazonaws.com/public/';
  public static IMAGE_DEFAULT = 'https://atria-images.s3.us-east-2.amazonaws.com/public/default.png';
  // public static BASE_URL = 'https://api.ichef.app.br/api/v1';
  // public static BASE_URL_DASH = 'https://minhaconta.iChef.app.br';
   public static BASE_URL_DASH = 'http://localhost:4200';
   public static BASE_URL = environment.host;


  static ITEMS       = '/items';

  static ACEIRO      = 'aceiro';
  static ANALYTIC      = 'analytic';
  static FOREST      = 'forest';
  static ROUTINE      = 'routine';
  static EVENT      = 'event';
  static OCCURRENCE      = 'occurrence';
  static PREVENT      = 'preventive';
  static TRAIL      = 'trail';
  static VISITOR      = 'visitor';
  

  static FIND_AND_COUNT_ALL = '/findAddCountAll';
  static LIST_DATA_TABLE = '/listDataTable';
  static LIST_OPERATOR_TYPE = '/operatorTypes';
  static LIST       = '/list';
  static FILTERS       = '/filters';
  static FIND_BY_ID = '/find';
  static FIND_BY_ID_INCLUDES = '/findByIdWithIncludes';
  static FIND_BY_FILE_NAME = '/findByName';
  static FIND_BY_CPF = '/find/cpf';
  static REPORT_BY_ID_YEAR_MONTH = '/report';
  static CREATE     = '/create';
  static UPDATE     = '/update';
  static DELETE     = '/delete';
  static DELETE_MANY     = '/deleteMany';
  static TOKEN      = '/token';
  static FORGOT      = '/forgot';
  static REWRITE      = '/rewrite';

  static USERS = '/users';
  static CATEGORIES = '/categories';
  static CIRCUITS = '/circuits';
  static PROFESSIONALS = '/professionals';
  static NEWS = '/news';
  static ADDRESS = '/addresses';
  static SHOP = '/shop';


  static endPointGET(modulePath: string) {
    console.log('*** endPointGET', Constants.BASE_URL + modulePath);
    return Constants.BASE_URL + modulePath;
  }

  static endPointALL(modulePath: string) {
    console.log('*** endPointGET', Constants.BASE_URL + modulePath);
    return Constants.BASE_URL + modulePath;
  }

  static endPointSave(modulePath: string, id: string) {
    console.log('*** endPointGET', Constants.BASE_URL + modulePath + '/' + id);
    return Constants.BASE_URL + modulePath + '/' + id;
  }

  static endPoint(modulePath: string) {
    return Constants.BASE_URL + modulePath;
  }

  static endPointDataTable(modulePath: string, apiPath: string, token: string) {
    return Constants.BASE_URL + modulePath + Constants.idioma + apiPath + '?token=' + token;
  }

  static endPointDataTableById(modulePath: string, apiPath: string, id: number, token: string) {
    console.log(Constants.BASE_URL + modulePath + Constants.idioma + apiPath + '/' + id + '?token=' + token);
    return Constants.BASE_URL + modulePath + Constants.idioma + apiPath + '/' + id + '?token=' + token;
  }

  static endPointEdit(modulePath: string, id: number) {
    return `/${modulePath}/${id}/edit`;
  }

}
