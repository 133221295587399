import { State, Selector, Action, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { ClearSearch, Search, SearchStateModel } from './searching.actions';

@State<SearchStateModel>({
  name: 'search',
  defaults: {
    term: null
  }
})

@Injectable()
export class SearchState {

  @Selector()
  static term(state: SearchStateModel): SearchStateModel  {
    return state;
  }

  constructor() {}

  @Action(Search)
  async search(ctx: StateContext<SearchStateModel>, action: Search) {
      // console.log('*** TERM', action.term);
      ctx.patchState({ term: action.term });
  }


  @Action(ClearSearch)
  async clearSearch(ctx: StateContext<ClearSearch>) {
      ctx.patchState({term: null });
  }

}
