import { Injectable } from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuthFacade } from './auth/auth.facade';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  // constructor(private authenticationService: AuthenticationService) {}
  constructor(private authFacade: AuthFacade) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // console.log('*** INTERCEPT ****', request.url);
    // console.log('*** RESEND FACTOR ****', Constants.FACTOR_RESEND);

    return this.authFacade.token$.pipe(
      switchMap((token: any) => {
        // console.log('*** ENTROU EM INTERCEPTOR', token);
        if (token !== null && token !== '') {
          const req = request.clone({
            setHeaders: {
              Authorization: `${token}`
            }
          });
          // console.log('*** REQ 1', JSON.stringify(req));
          return next.handle(req);
        } else {
            const req2 = request.clone({
                setHeaders: {
                  Authorization: `${localStorage.getItem('token_site')}`
                }
              });
          // console.log('*** REQ 2', JSON.stringify(req2));
            return next.handle(req2);
        }
      })
    );
  }
}
