import { UserService } from './services/user/user.service';
import { AuthService } from './../../modules/auth/_services/auth.service';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { environment } from 'src/environments/environment';

import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import {NgxsModule} from '@ngxs/store';
import { AuthState } from 'src/app/modules/auth/store/auth.state';
import { SearchState } from './store/searching/searching.state';
import { ExcelService } from './services/excel-export/excel.service';
import { ExportService } from './services/export.service';
import { ALL_SERVICES } from './services/all';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    NgxsModule.forRoot([AuthState, SearchState], { developmentMode: !environment.production}),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsRouterPluginModule.forRoot(),
    NgxsStoragePluginModule.forRoot({ key: [AuthState, SearchState] }),
  ],
  providers: [
    AuthService, 
    UserService, 
    ALL_SERVICES,
    ExcelService, ExportService],
  exports: [],

})
export class CoreModule {

}
