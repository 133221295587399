
export class NgxsEntityAdapter {

    static addAll( collection: any[], count: number, context:any,  keyField = 'id' ) {
      const entities = collection.reduce(( object, item ) => {
        object[item[keyField]] = item;
        return object;
      }, {});
  
      context.patchState( {
        ...context.getState(), entities, count
      });
    }
  
    static addItem( entity: any, context:any, keyField = 'id' ) {
      context.patchState({
        ...context.getState(),
        entities: {
          ...context.getState().entities,
          [entity[keyField]]: entity
        }
      });
    }
  
    static editItem( entity: any, context:any, keyField = 'id' ) {
      context.patchState({
        entities: {
          ...context.getState().entities,
          [entity[keyField]]: {
            ...entity
          }
        }
      });
    }
  
    static removeItem( entity: any, context:any, keyField = 'id' ) {
  
      const entityCloned = Object.assign({}, context.getState().entities);
      delete entityCloned[entity[keyField]];
  
      context.patchState({
        ...context.getState(),
        entities: {
          ...entityCloned,
        }
      });
    }
  
    static select( entity: any, context: any ) {
      context.patchState({
        selected: entity
      });
    }
  
    static setLoading( context:any, value: boolean = false ) {
      context.patchState({
        loading: value
      });
    }
  
    static setItems( context:any, items: any[], count: number ) {
      context.patchState({
        items, count
      });
    }
  
    static setSuccess( context:any, value: string ) {
      context.patchState({
        success: value
      });
    }
  
    static setItemsSelected( context:any, items: any[] ) {
      console.log('setItemsSelecte entreou', items);
      context.patchState({
        itemsSelected: items
      });
    }
  
    static setItemSelected( context:any, item: any ) {
      context.patchState({
        itemSelected: item
      });
    }
  
  
    static setDualList( context: any, listFrom: any[], listTarget: any[] ) {
      context.patchState({
        dualListFrom: listFrom,
        dualListTarget: listTarget,
      });
    }
  
  
    static setError(context: any, value: string = '' ) {
      context.patchState({
        error: value
      });
    }
  
  }
  