import { AuthStateModel } from './auth.statemodel';
import { State, Selector, Action, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { Navigate } from '@ngxs/router-plugin';
import { AuthService } from '../_services/auth.service';
import { ClearError, Forgot, ForgotConfirm, Logout, ResendFactor, SetLogin, SetSync, SignUp } from './auth.actions';
import { NgxsEntityAdapter } from 'src/app/_metronic/core/services/ngxsentityadapter.service';
import { TOKEN_TYPE } from 'src/app/_metronic/core/services/constants';



@State<AuthStateModel>({
  name: 'authonboard',
  defaults: {
    email: '',
    token: '',
    credentialId: 0,
    username: '',
    image: '',
    roles: [],
    firstName: '',
    password: '',
    token_type: TOKEN_TYPE.TWO_FACTOR,
    error: '',
    success: '',
    loading: false,
    latitude: 0,
    longitude: 0
  }
})

@Injectable()
export class AuthState {

  constructor(
    private authService: AuthService,
  ) {}

  @Selector()
  static auth(state: AuthStateModel): AuthStateModel  {
    console.log('*** AUTH ****', state);
    return state;
  }

  static signin(state: AuthStateModel): any  {
    return { username: state.username, password: state.password};
  }

  static twoFactor(state: AuthStateModel): any  {
    return { code: '', username: state.username};
  }

  @Selector()
  static token(state: AuthStateModel): string | null {
    return state.token;
  }

  @Selector()
  static error(state: AuthStateModel): string | null {
    return state.error;
  }


  @Selector()
  static tokenType(state: AuthStateModel): string | null {
    return state.token_type;
  }

  @Selector()
  static isAuthenticated(state: AuthStateModel): boolean {
    console.log('*** AUTH LOGIN ***', state);
    return (state.token !== '') ? true : false;
  }


  @Action(SetLogin)
  async setLogin(ctx: StateContext<AuthStateModel>, action: SetLogin) {
    console.log('*** SETLOGIN', action);

    ctx.patchState({
      token: action.auth.token, username: action.auth.username, firstName: action.auth.firstName,
      cartorioName: action.auth.cartorioName, cartorioServentia: action.auth.cartorioServentia,
      loggedAt: action.auth.loggedAt, roles: action.auth.roles,
      credentialId: action.auth.credentialId, password: action.auth.password,
      token_type: action.auth.type, error: null, loading: false });
      ctx.dispatch(new Navigate(['/']));
  }


  @Action(Forgot)
  forgot(ctx: StateContext<AuthStateModel>, action: Forgot) {
      NgxsEntityAdapter.setItemSelected(ctx, action.payload);
  }

  @Action(ForgotConfirm)
  forgotConfirm(ctx: StateContext<AuthStateModel>, action: ForgotConfirm) {
    NgxsEntityAdapter.setLoading(ctx, true);

    // return this.authService.forgotConfirm(action.token, action.password, action.passwordConfirm)
    // .pipe(take(1)).toPromise()
    // .then(res => {
    //   console.log('FORGOT RES', res);
    //   NgxsEntityAdapter.setItemSelected(ctx, res);
    //   NgxsEntityAdapter.setLoading(ctx, false);
    //   ctx.dispatch(new Navigate(['dashboard']));
    // })
    // .catch(err => {
    //   NgxsEntityAdapter.setError(ctx, err.message);
    //   NgxsEntityAdapter.setLoading(ctx, false);
    //   console.log('err', err);
    // });
  }

  @Action(ClearError)
  clearError(ctx: StateContext<AuthStateModel>) {
    NgxsEntityAdapter.setError(ctx, '');
  }

  @Action(ResendFactor)
  async resendFactor(ctx: StateContext<AuthStateModel>) {
    NgxsEntityAdapter.setLoading(ctx, true);
    NgxsEntityAdapter.setSuccess(ctx, '');
    NgxsEntityAdapter.setError(ctx, '');

    console.log('*** STATE *****', ctx.getState());

    this.authService.resendFactor(ctx.getState().email).pipe
    (take(1)).toPromise()
    .then(result => {
      console.log('*** RESEND FACTOR *****', result);
      NgxsEntityAdapter.setLoading( ctx, false);
     })
    .catch(err => {
      NgxsEntityAdapter.setError(ctx, err.message);
      NgxsEntityAdapter.setLoading(ctx, false);
    });
  }


  @Action(SignUp)
  async signUp(ctx: StateContext<AuthStateModel>, action: SignUp) {
    NgxsEntityAdapter.setLoading(ctx, true);
    NgxsEntityAdapter.setSuccess(ctx, '');
    NgxsEntityAdapter.setError(ctx, '');

  }

  @Action(SetSync)
  dateSync(ctx: StateContext<AuthStateModel>, action: SetSync) {
    console.log('entrou em logout');
    ctx.setState({ ...ctx.getState(), dateSync: action.dateSync });
  }

  @Action(Logout)
  logout(ctx: StateContext<AuthStateModel>) {
    console.log('entrou em logout');
    ctx.setState({
      credentialId: 0, token: '',
      email: '', username: '',
      token_type: '', error: '',
      roles: [],
      password: ''});
    ctx.dispatch(new Navigate(['/auth/login']));
  }
}
