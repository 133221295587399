export const OPERATORS = [
    {label: 'equal', value: '[Op.eq]'},
    {label: 'like', value: '[Op.like]'},
  ];
  
  export enum FILTER_TYPE {
    EMAIL = 'email',
    TEXT = 'text',
    NUMBER = 'number',
    DATE = 'date',
    INTERVAL = 'interval',
    BOOLEAN = 'boolean',
    GROUP= 'group'
  }
  
  export enum PERMISSOES {
    CREATE_USER = 'CREATE_USER',
    UPDATE_USER = 'UPDATE_USER',
    DELETE_USER = 'DELETE_USER',
    VIEW_USER = 'VIEW_USER',
    VIEW_LIST_USERS = 'VIEW_LIST_USERS',
    TORNAR_ADMIN = 'TORNAR_ADMIN',
    CREATE_EVENTO = 'CREATE_EVENTO',
    UPDATE_EVENTO = 'UPDATE_EVENTO',
    DELETE_EVENTO = 'DELETE_EVENTO',
  }
  
  
  export interface Filter {
    fieldName: string;
    fieldValue: any[];
    matchMode: string;
    id?: string;
    type?: string;
    // children: Filter[];
  }
  
  export interface Include {
    attributes: string[];
    model: string;
    as: string;
  }
  
  
  export interface Order {
    field: string;
    value: number;
  }
  
  export interface ModelFilter {
    fieldName: string;
    fieldValue: any[];
    matchMode: string;
    // children: Filter[];
  }
  
  
  export class Filter {
  
  
    public static transFormToIFilters(model: any): Filter[] {
  
      const filters: Filter[] = [];
  
      if (
        model.strings === undefined && model.numbers === undefined
        && model.radios === undefined  && model.dates === undefined
        && model.betweenNumbers === undefined  && model.tags === undefined ) { return filters; }
  
      for (const item of model.strings) {
        const arr: any[] = [];
        arr.push(item.fieldValue);
        filters.push({ fieldName: item.fieldName, fieldValue: arr, matchMode: item.matchMode });
      }
  
      for (const item of model.numbers) {
        const arr: any[] = [];
        arr.push(item.fieldValue.toString());
        filters.push({ fieldName: item.fieldName, fieldValue: arr, matchMode: item.matchMode });
      }
  
      for (const item of model.radios) {
        const arr: any[] = [];
        arr.push(item.fieldValue);
  
        filters.push({ fieldName: item.fieldName, fieldValue: arr, matchMode: item.matchMode });
      }
  
      for (const item of model.dates) {
        const arr: any[] = [];
        arr.push(this.formatDate(item.startDate));
        arr.push(this.formatDate(item.endDate));
  
        filters.push({ fieldName: item.fieldName, fieldValue: arr, matchMode: item.matchMode });
      }
  
      for (const item of model.betweenNumbers) {
        const arr: any[] = [];
        arr.push(item.number1.toString());
        arr.push(item.number2.toString());
  
        filters.push({ fieldName: item.fieldName, fieldValue: arr, matchMode: item.matchMode });
      }
  
      for (const item of model.tags) {
        filters.push({ fieldName: item.fieldName, fieldValue: item.fieldValue.map(a => a.value), matchMode: item.matchMode });
      }
  
      return filters;
  
    }
  
    private static formatDate(dateNumbers: any): string {
      return new Date(dateNumbers.year + '-' + dateNumbers.month + '-' + dateNumbers.day).toISOString();
    }
  }
  