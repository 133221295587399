import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Navigate } from '@ngxs/router-plugin';
import { AuthState } from 'src/app/modules/auth/store/auth.state';
import { Forgot, ForgotConfirm, Logout, SetLogin } from 'src/app/modules/auth/store/auth.actions';


@Injectable({ providedIn: 'root' })

export class AuthFacade {

  public constructor(private store: Store) { }

  @Select(AuthState.signin) signin$: Observable<any>;
  @Select(AuthState.auth) auth$: Observable<any>;
  @Select(AuthState.token) token$: Observable<string>;
  @Select(AuthState.error) error$: Observable<string>;
  @Select(AuthState.isAuthenticated) isAuthenticated$: Observable<boolean>;


  public setLogin(payload: any) {
    this.store.dispatch(new SetLogin(payload));
  }

  
  public logout() {
    this.store.dispatch(new Logout());
  }

  public forgot(payload: any) {
    this.store.dispatch(new Forgot(payload));
  }

  public forgotConfirm(token: string, password: string, passwordConfirm: string) {
    this.store.dispatch(new ForgotConfirm(token, password, passwordConfirm));
  }

  public navigateTo(url: string) {
    this.store.dispatch(new Navigate([url]));
  }

}
