import { Injectable } from '@angular/core';
import {Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, take } from 'rxjs/operators';
import { Constants } from 'src/app/_metronic/core/services/constants';
import { Filter, Order } from 'src/app/formly/models/filters.model';
import { query } from '@angular/animations';

@Injectable({
  providedIn: 'root'
})

export class AuthService {

    constructor(private http: HttpClient) { }

    getUsers(): Observable<any> {
      return this.http.get(Constants.BASE_URL + 'securities')
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
    }

    getUser(id: string): Observable<any> {
      return this.http.get(Constants.BASE_URL + 'securities/' + id)
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
    }


    getRoles(): Observable<any> {
      return this.http.get(Constants.BASE_URL + 'roles')
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
    }

    createUser(payload: any): Observable<any> {
      return this.http.post(Constants.BASE_URL + 'securities/signup', payload)
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
    }

    deleteUser(id: string): Observable<any> {
      return this.http.delete(Constants.BASE_URL + 'securities/' + id)
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
    }

    updateUser(payload: any): Observable<any> {
      return this.http.post(Constants.BASE_URL + 'securities/updateuser', payload)
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
    }

    filters(
      limit: number, offset: number, modelFilters: any[], orders: Order[],
      includes?: any[], filtersFormatted?: Filter[], attributes?: string[]): Observable<any[]> {
  
      const filters: Filter[] = (filtersFormatted) ? filtersFormatted : Filter.transFormToIFilters(modelFilters);
  
      includes = (includes) ? includes : [];
  
      const payload = {attributes, limit, offset, filters, includes, orders};
  
      return this.http.post<any[]>(Constants.endPoint('securities/filters'), payload).pipe(
        map(items => items),
        catchError((err) => {
          console.log('error caught in service', err);
          return throwError(err);
        })
      );
    }
    

    sendMail(payload: any): Observable<any> {
      return this.http.post<any>(Constants.BASE_URL + 'mail/post', payload)
      .pipe(
        catchError((err) => {
          console.log('error caught in service', err);
          return throwError(err);
        })
      );
    }

    signinMailPassword(username: string, password: string): Observable<any> {
      
      return this.http.post<any>(Constants.BASE_URL + 'securities/token', { username, password})
      .pipe(
        catchError((err) => {
          console.log('error caught in service', err);
          return throwError(err);
        })
      );
    }


    createFactor(payload: any): Observable<any> {
      console.log('CREATE FACTOR PAYLAOD', payload);
      return this.http.post<any>(Constants.BASE_URL + 'securities/createfactor', payload)
      .pipe(
        catchError((err) => {
          console.log('error caught in service', err);
          return throwError(err);
        })
      );
    }

    signinTwoFactor(payload: any, token: string): Observable<any> {
      console.log('userSignin TWOFACTOR', payload);
      return this.http.post<any>(Constants.BASE_URL + 'securities/twoFactor',
      { username: payload.username, code: payload.code, deviceId: payload.deviceId },
      { headers: new HttpHeaders().set('Authorization', token) })
      .pipe(
        catchError((err) => {
          console.log('error caught in service', err);
          return throwError(err);
        })
      );
    }

    forgot(username: string): Observable<any> {
      return this.http.post<any>(Constants.BASE_URL + 'securities/forgot', { username});
    }

    forgotConfirm(token: string | null, password: string, passwordConfirm: string): Observable<any> {
      const payload = {
        password,
        rewritePassword: passwordConfirm
      }
      console.log('PAYLOAD', payload);
      return this.http.post<any>(Constants.BASE_URL + 'securities/rewrite?token=' + token, payload);
    }

    findProfile(email: string): Observable<any> {
      return this.http.get(Constants.BASE_URL + 'securities/findProfile/' + email)
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
    }



    resendFactor(email: string): Observable<any> {
      return this.http.post<any>(Constants.BASE_URL + 'user/resendfactor', { email })
      .pipe(
        catchError((err) => {
          console.log('error caught in service', err);
          return throwError(err);
        })
      );
    }

  }
