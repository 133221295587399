import {  Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Constants } from './constants';
import { BaseResource } from 'src/app/formly/models/basersource.model';
import { Filter, Order } from '../models/filters.model';

export abstract class GenerichttpService<T extends BaseResource> {
  protected http: HttpClient;

  constructor(
    protected apiPath: string,
    protected injector: Injector
  ) {
    this.http = injector.get(HttpClient);
  }

  get(): Observable<T[]> {
    return this.http.get<T[]>(Constants.endPoint(this.apiPath)).pipe(
      catchError(this.handleError)
    );
  }


  getById(id: number): Observable<T> {
    return this.http.get<T>(`${Constants.endPoint(this.apiPath)}/${id}`).pipe(
      catchError(this.handleError)
    );
  }

  post(resource: T): Observable<T> {
    return this.http.post<T>(Constants.endPoint(this.apiPath), resource).pipe(
      catchError(this.handleError)
    );
  }

  put(resource: T): Observable<T> {
    return this.http.put(`${Constants.endPoint(this.apiPath)}/${resource.id}`, resource).pipe(
      map(() => resource),
      catchError(this.handleError)
    );
  }

  delete(id: number): Observable<any> {
    return this.http.delete(`${Constants.endPoint(this.apiPath)}/${id}`).pipe(
      map(() => null),
      catchError(this.handleError)
    );
  }

  filters(
    limit: number, offset: number, modelFilters: any[], orders: Order[],
    includes?: any[], filtersFormatted?: Filter[], attributes?: string[]): Observable<T[]> {

    const filters: Filter[] = (filtersFormatted) ? filtersFormatted : Filter.transFormToIFilters(modelFilters);

    includes = (includes) ? includes : [];

    const payload = {attributes, limit, offset, filters, includes, orders};

    return this.http.post<T[]>(Constants.endPoint(this.apiPath + Constants.FILTERS), payload).pipe(
      map(items => items),
      catchError(this.handleError)
    );
  }

  protected handleError(error: any): Observable<any> {
    console.log('ERRO NA REQUISIÇÃO => ', error);
    return throwError(error);
  }

}
