
export class SetLogin {
  static readonly type = '[Auth] SetLogin';
  constructor(public auth: any) {}
}

export class SetLocation {
  static readonly type = '[Auth] SetLocation';
  constructor(public latitude: number, public longitude: number) {}
}

export class SetSync {
  static readonly type = '[Auth] SetSync';
  constructor(public dateSync: string) {}
}

export class SetGroup {
  static readonly type = '[Auth] SetGroup';
  constructor(public group: any) {}
}

export class ClearGroup {
  static readonly type = '[Auth] ClearGroup';
}

export class SignUp {
  static readonly type = '[Auth] SignUp';
  constructor(public signup: any) {}
}

export class ResendFactor {
  static readonly type = '[Auth] ResendFactor';
}


export class ClearError {
  static readonly type = '[Auth] ClearError';
}

export class Flow {
  static readonly type = '[Auth] Flow';
  constructor(public result: any) {}
}

export class Logout {
  static readonly type = '[Auth] Logout';
}

export class Forgot {
  static readonly type = '[Auth] Forgot';
  constructor(public payload: any) {}
}

export class ForgotConfirm {
  static readonly type = '[Auth] ForgotConfirm';
  constructor(public token: string, public password: string, public passwordConfirm: string) {}
}
