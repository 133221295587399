import { Role } from './../../models/role.model';
import { Injectable, Injector } from "@angular/core";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { UserModel } from "src/app/modules/auth";
import { GenerichttpService } from "../basehttp.service";
import { Constants } from "../constants";


@Injectable({
  providedIn: 'root'
})

export class UserService extends GenerichttpService<UserModel> {

  constructor(protected injector: Injector) {
    super(Constants.USERS.toString(), injector);
  }

  findByEmail(email: string): Observable<UserModel> {
    const URL = Constants.endPointALL(Constants.USERS + `/teste?email=${email}`);
    console.log('URL', URL);
    return this.http.get<UserModel>(URL).pipe(
      // map(this.jsonDataToResources.bind(this)),
      catchError(this.handleError)
    );
  }

  findRoleByName(name: string): Observable<Role> {
    const URL = Constants.endPointALL(Constants.USERS + `/role?name=${name}`);
    console.log('URL', URL);
    return this.http.get<Role>(URL).pipe(
      catchError(this.handleError)
    );
  }

}
