import { HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { GenerichttpService } from '../basehttp.service';
import { Constants } from '../constants';


export interface IAnalytic {
    id?: any; 
    credentialId: string;
    selosEstoque:number;
    selosUtilozadosDia:number;
    valoresdodiaEmolumentos:number;
    valoresdodiaFundos: number;
    valoresdodiaTotal: number;
    taxasGrerj: number;
    taxasIss: number;
    taxasDistribuicao: number;
    notasAtosQtd: number;
    notasAtosTotal: number;
    notasFirmasQtd: number;
    notasFirmasTotal: number;
    rgiProtocolosQtd:number;
    rgiAtosseladosQtd:number;
    rgiProtocolosTotal: number;
    rgiAtosseladosTotal: number;
    demaisProtestoQtd: number;
    demaisProtetoTotal: number;
    demaisRtdQtd: number;
    demaisRtdTotal: number;
    demaisRcpnQtd: number;
    demaisRcpnTotal: number;
}

@Injectable({
  providedIn: 'root'
})



export class AnalyticService extends GenerichttpService<IAnalytic> {

  constructor(protected injector: Injector) {
    super(Constants.ANALYTIC.toString(), injector);
  }

  getReport(token: string, credentialId: string, date: string): Observable<any> {

    let query = `credentialId=${credentialId}`;
    if (date) { query += `&date=${date}`; }
    console.log('*** URL', Constants.BASE_URL + `analytic?${query}`);

    return this.http.get(Constants.BASE_URL + `analytic/report?token=${token}&${query}`)
    .pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }
  
}
